const EOLPage = () => {
    return (
        <div style={{ margin: "20px 40px" }}>
            <p>
	    	Hello,<br /><br />My name is Garlion and I&apos;ve been selected to take over the project.
	        The new environment currently has a number of bugs, but it&apos;s still a resource
	        you can use (mostly) as normal. I&apos;m still in the process of porting the site over
	        so you&apos;ll find some links dont work as of yet.
            </p>

            <p>
                One of the things you&apos;ll notice is that the Account menu does not work. Unfortunately,
	      	at this time, the collections have not been migrated. I&apos;ll be looking to see if it&apos;s possible
	    	but, you may need to import your collection over once I have the account portion of the site back up.
	    	(I hope you had a chance to export your collection from PirateMermaid&apos;s site prior to it going down.)
            </p>

            <p>
                I&apos;m still refreshing myself on the coding that&apos;s involved here, so it will probably take time to get everything
	    	sorted out. 
	    </p>

	    <p>
	    	Once the site is fully functional again, I plan to do an overhaul of the entire project. There are plenty of ideas
	    	I have rolliing around (a TC calculator using your saved collection and map editor with the ability to save
		your designs to name a few).
            </p>

	    <p>
	    	I can be reached on <a href="https://discord.gg/dmk">DMK&apos;s Community Server</a> and am providing updates there as 
	    	things progress.
	    </p>
        </div>
    );
};

export default EOLPage;
