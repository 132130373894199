const APPDATA = {
    FETCH: "APPDATA_FETCH",
    FETCH_ERROR: "APPDATA_FETCH_ERROR",
    FETCH_SUCCESS: "APPDATA_FETCH_SUCCESS",
    FETCH_EVENT_DATA: "FETCH_EVENT_DATA",
    FETCH_CHARACTER_GROUPS: "FETCH_CHARACTER_GROUPS",
    FETCH_ATTRACTION_ORDER: "FETCH_ATTRACTION_ORDER"
};

const USERDATA = {
    FETCH: "USERDATA_FETCH",
    FETCH_ERROR: "USERDATA_FETCH_ERROR",
    FETCH_SUCCESS: "USERDATA_FETCH_SUCCESS",
    BATCH_UPDATE: "BATCH_UPDATE",
    UPDATE_CHARACTER: "UPDATE_CHARACTER",
    UPDATE_ATTRACTION: "UPDATE_ATTRACTION",
    UPDATE_TARGET_LEVEL: "UPDATE_TARGET_LEVEL",
    CLEAR_USERDATA: "CLEAR_USERDATA"
};

const APPSTATE = {
    AUTH_USER: "AUTH_USER",
    TOGGLE_WORK_MODE: "TOGGLE_WORK_MODE",
    TOGGLE_NIGHT_MODE: "TOGGLE_NIGHT_MODE"
};

export { APPDATA, USERDATA, APPSTATE };
