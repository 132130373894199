import _ from "lodash";
import React, { Component } from "react";
import clsx from "clsx";
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";

import Page from "../../components/Page/Page";
import TaskList from "../../components/guides/TaskList";
import Loading from "../../components/states/Loading";
import { sortTasksByTime } from "../../utils/utils";

/**
 * TaskGuide page displays the selected character's tasks
 */
class TaskGuide extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            selected: "",
            taskList: [],
            sortedTasks: [],
            sortBy: "level",
            taskSortedFlag: false
        };

        this.handleSelectName = this.handleSelectName.bind(this);
        this.sortTasks = this.sortTasks.bind(this);
    }

    handleSelectName(name) {
        this.setState({ selected: name.value });
        this.getTasks(name.value)
            //.then(res.data => tasks);
        //this.componentDidMount(name.value);
    }

    handleSortChange(sortBy) {
        this.setState({ sortBy });
        if (this.state.tasks?.length) {
            this.setState({
                sortedTasks: this.sortTasks(this.state.tasks, sortBy)
                //sortedTasks: this.state.tasks
            });
        }
    }

    sortTasks(tasks, sortBy) {
        const { selected } = this.state;

        if (sortBy === "level") {
            console.log(this.state)
            //console.log(tasks)
            /*if (!this.props.tasks) {
				return tasks;
			}*/
            
            if (!this.taskSortedFlag) {

                return tasks.sort((a, b) => {
                    //console.log('a:');
                    //console.log(a);
                    const charA = _.find(a.taskCharacters, { name: selected });
                    const levelA = charA.level;
                    //console.log('b:');
                    //console.log(b);
                    const charB = _.find(b.taskCharacters, { name: selected });
                    const levelB = charB.level;

                    return levelA - levelB;
                });
            } else {
                return tasks;
            }
        } else {
            return sortTasksByTime(tasks);
        }
    }

    // TODO: skip obtained characters' tokens
    async getTasks(name, sortBy) {
        this._isMounted = true;
        //const params = new URLSearchParams([['name', name]]);
        //console.log(params);
        console.log(name)
        //const res = await axios.get('http://www.dmktokenguide.com/api/app/lookup_character?'+name.replace(' ','+'))
        //    .then(res => console.log(JSON.stringify(res.data,null,2)));

        await axios({
            method: "get",
            url: "/api/app/lookup_character",
            params: { name }
        }).then((res) => {
            //const { conflicts, tokens } = res.data;
            console.log(res);
            const { tasks } = res.data;
            this.taskSortedFlag = false;
            this.setState({ 
                tasks, 
                sortedTasks: this.sortTasks(tasks, this.state.sortBy)
            })
            this.taskSortedFlag = true;
            //this.setState({ conflicts, tokenInfo: tokens });
        })
        .catch(err => {
            console.log(err)
        });
        
        /*const res = await axios({
            method: "get",
            url: "/api/app/lookup_character",
            params: { name }
        })*/
        /*.then(
            res.data => tasks;
             this.setState({
                tasks,
                sortedTasks: this.sortTasks(tasks, this.state.sortBy)
            });

        )*/

/*        if(res.status == 200) {
            console.log(res.status)
        }*/
        /*if (this._isMounted) {
            const { tasks } = res.data;

            this.setState({
                tasks,
                sortedTasks: this.sortTasks(tasks, this.state.sortBy)
            });
        };*/
    }

    componentWillUnmount() {
        this._isMounted = false;
    }



    render() {
        if (!this.props.appData) {
            return <Loading />;
        }

        //if (this._isMounted) {

            return (
                <Page
                    header="Task Guide"
                    subheader="Look up a character's tasks"
                    id="task-guide"
                >
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div id="select-row-task-guide" className="col s12">
                            <Select
                                value={this.state.selected}
                                onChange={this.handleSelectName}
                                options={this.props.appData.characters.map(
                                    ({ name }) => {
                                        return { value: name, label: name };
                                    }
                                )}
                                placeholder="Select character..."
                            />
                        </div>
                    </div>
                    <div className="row center">
                        Sort by:{" "}
                        <span
                            onClick={() => this.handleSortChange("level")}
                            className={clsx(
                                "sort-selector",
                                this.state.sortBy === "level" && "selected"
                            )}
                        >
                            Level
                        </span>{" "}
                        |{" "}
                        <span
                            onClick={() => this.handleSortChange("time")}
                            className={clsx(
                                "sort-selector",
                                this.state.sortBy === "time" && "selected"
                            )}
                        >
                            Time
                        </span>
                    </div>
                    <div className="row">
                        <div className="col l6 offset-l3 s12">
                            {this.state.selected && this.state.tasks && !this.state.taskSortedFlag ? (
                                <TaskList
                                    sortBy={this.state.sortBy}
                                    sortTasks={this.sortTasks}
                                    selected={this.state.selected}
                                    tasks={this.state.sortedTasks}
                                    useUserData={this.props.useUserData}
                                    characters={this.props.appData.characters}
                                />
                            ) : null}
                        </div>
                    </div>
                </Page>
            );
        //}
    }
}

const mapStateToProps = (state) => {
    const { appData, userData } = state;
    return { appData, userData };
};

export default connect(mapStateToProps)(TaskGuide);
